<script>
import Layout from "../../../layouts/main";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
import readXlsxFile from 'read-excel-file'
import router from '@/router'
import Swal from "sweetalert2";
import {formatNumber} from '@/utils/formatter'

export default {
  page: {
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Multiselect,
    DatePicker,
    Layout,
    PageHeader,
  },
  setup() {
    const { $get } = useHttp()
    const { $post } = useHttp()
    const form = ref({
      sprmVendorId : '',
      sprmEffectivedate : '',
      details:[]
    })
    const valVendor = ref({})
    const valEffectivedate = ref({})
    
    const LOV = ref({
      vendor: []
    })
    
    const getVendor = async () => { 
      const {data} = await $get({ url: 'master/vendor'})
      LOV.value.vendor = data
    }
    onMounted(() => {
     getVendor()
    })

const onFileChange = async () => {
    let txtfile = document.getElementById('txtfile')
    readXlsxFile(txtfile.files[0]).then((rows) => {
      rows.splice(0, 1)
      rows.map(function(value) {
        form.value.details.push({
              sprdOrigportinit : value[0],
              sprdDestportinit : value[1],
              sprdPriceKg: value[2]
        })
      });
    })
  }

    const resetForm = ()=> {
      form.value = {details:[]}
    }
    
    const postForm = async () => {
      if(!valVendor.value.id){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Vendor is required",
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }
      if(typeof valEffectivedate.value.length === 'undefined'){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Effective Date is required",
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }
      if(!form.value.details[0]){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Please upload the schedule data first",
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }
      
      form.value.sprmVendorId = valVendor.value.id
      form.value.sprmEffectivedate = valEffectivedate.value
      const response = await $post({ url: 'master/scheduleprice',
        data: form.value
      })
      if(response.status === 'success'){
        router.push({name: 'appsmd-scheduleprice-list'})
      }
    }
    return{
      postForm,
      form,
      resetForm,
      formatNumber,
      valVendor,
      valEffectivedate,
      LOV,
      onFileChange
    }
  },
  methods: {},
  data() {
    return {
      title: "Price Upload",
      items: [],
      select_label: "Select"
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Vendor"
                    label-for="txtvendor"
                  >
                    <multiselect id="txtvendor" v-model="valVendor" :options="LOV.vendor" label="name" track-by="name" :select-label="select_label"></multiselect>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Effective Date"
                    label-for="txteffectivedate"
                  >
                    <date-picker id="txteffectivedate" lang="en" v-model="valEffectivedate" format='DD-MM-YYYY' valueType="format"></date-picker>
                  </b-form-group>
                  
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="File"
                    label-for="txtfile"
                  >
                    <div class="custom-file">
                      <input id="txtfile" type="file" class="custom-file-input" @change="onFileChange" />
                      <label class="custom-file-label" for="txtfile">Choose file</label>
                    </div>
                  </b-form-group>
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row,index) in form.details" :key="index">
                    <th scope="row" class="text-right">{{index+1}}</th>
                    <td class="text-center">{{row.sprdOrigportinit}}</td>
                    <td class="text-center">{{row.sprdDestportinit}}</td>
                    <td class="text-right">{{formatNumber(row.sprdPriceKg)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click="postForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" @click="resetForm">
                    <i class="mdi mdi-refresh align-middle"></i> Reset
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'appsmd-scheduleprice-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

  </Layout>
</template>